<template>
    <el-dialog
        title="【权限分配】"
        :visible.sync="AuthRouteDialogVisible"
        width="50%"
        top="5vh"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeAuthRouteDialog()"
    >
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="col_div">主权限配置</div>
                <common-auth-component ref="main_auth" :id="id" :AllAuthRequest="AllMainAuthRequest" :HaveAuthRequest="HaveMainAuthRequest"></common-auth-component>
            </el-col>
            <el-col :span="12">
                <div class="col_div">查看限制配置</div>
                <common-auth-component ref="look_auth" :id="id" :AllAuthRequest="AllLookAuthRequest" :HaveAuthRequest="HaveLookAuthRequest"></common-auth-component>
            </el-col>
        </el-row>
        <div slot="footer">
            <el-button
                type="success"
                plain
                @click="submitAuth"
                >确定</el-button
            >
            <el-button
                plain
                @click="closeAuthRouteDialog"
                >取消</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import CommonAuthComponent from './CommonAuthComponent'
import { all_main_auth_request,position_main_auth_request,all_look_auth_request,position_look_auth_request,edit_position_main_auth_request } from '@/network/auth.js'

export default {
    name:'CommonAuthDialog',
    data(){
        return {
            AllMainAuthRequest: all_main_auth_request,
            HaveMainAuthRequest: position_main_auth_request,
            AllLookAuthRequest: all_look_auth_request,
            HaveLookAuthRequest: position_look_auth_request
        }
    },
    computed:{},
    methods:{
        closeAuthRouteDialog() {
            this.$emit('exitAuthRouteDialog')
        },
        submitAuth() {
            const data = {
                id: this.id,
                route_id: this.$refs.main_auth.getCheckedKeys(),
                look_auth_id: this.$refs.look_auth.getCheckedKeys()
            }
            edit_position_main_auth_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('授权成功!')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
                .finally(() => this.closeAuthRouteDialog())
        }
    },
    filters:{},
    props:{
        AuthRouteDialogVisible: Boolean,
        id: Number,
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonAuthComponent
    },
    watch:{}
}
</script>

<style lang='less'>
.col_div{
    text-align: center;
    margin-bottom: 20px;
}
</style>