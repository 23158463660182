<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="职位名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="职位名称搜索"
                    class="positionc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="所属企业" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_department(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in fAllenterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属部门" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    placeholder="选择部门"
                    class="departmentc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="department in this.departmentInEnterprise"
                        :key="department.name"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                    v-model="form.status"
                    placeholder="选择状态"
                    class="statusc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['禁用','可用']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加职位</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { department_list_request } from '@/network/list.js'

export default {
    name: 'PositionSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                enterprise_id: '',
                department_id: '',
                status: 1,
                page: 1,
            },
            departmentInEnterprise: [],
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.departmentInEnterprise = []
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_department(enterprise_id) {
            this.form.department_id = ''
            this.departmentInEnterprise = []
            if (enterprise_id) {
                department_list_request({ enterprise_id })
                    .then((s) => {
                        this.loading = false
                        if (s.status === 0) {
                            this.departmentInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err)
                    })
            }
        },
    },
    filters: {},
    props: {
        fAllenterprise:Array,
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>