import request from './request'

//职位列表
export const position_index_request = p => {
    return request({
        method:'GET',
        url:'position/index',
        params: p
    })
}

//添加职位
export const position_add_request = d => {
    return request({
        method:'POST',
        url:'position/add',
        data: d
    })
}

//删除职位
export const position_del_request = id => {
    return request({
        method:'DELETE',
        url:'position/del',
        data: {
            id: id
        }
    })
}

//修改职位
export const position_edit_request = data => {
    return request({
        method:'PUT',
        url:'position/edit',
        data
    })
}

//获取职位详情
export const position_details_request = id => {
    return request({
        method:'GET',
        url:'position/details',
        params: {
            id
        }
    })
}