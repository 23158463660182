<template>
    <div v-loading.fullscreen.lock="loading">
        <position-search-bar-component
            add_auth="新增职位"
            :fAllenterprise="fAllenterprise"
            @search="get_position_index"
            @addTeam="addTeam"
        ></position-search-bar-component>
        <common-table-component
            details_auth="职位详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_position"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_position_index"
        ></common-page-component>
        <position-edit-component
            edit_auth="修改职位"
            del_auth="删除职位"
            allot_auth="职位授权"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :position_details_data="fposition_details_data"
            :fenterprise_department="fenterprise_department"
            @exitPositionDialog="dialogExit"
            @search="get_position_index"
            @show_edit="show_edit"
            @details_row="details_position"
        ></position-edit-component>
    </div>
</template>

<script>
import { position_index_request,position_details_request } from '@/network/position.js'
import { enterprise_department_request} from '@/network/enterprise.js'
import { enterprise_list_request} from '@/network/list.js'

import PositionSearchBarComponent from '@/components/position/PositionSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import PositionEditComponent from '@/components/position/PositionEditComponent'

export default {
    name: 'PositionView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                enterprise_id: '',
                department_id: '',
                status: 1,
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '职位名称',
                    minWidth: '150px'
                },
                {
                    prop: 'status',
                    label: '可用状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'danger',
                                filter_data: '禁用'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '启用'
                            }
                        }
                    }
                },
                {
                    prop: 'department_name',
                    label: '所属部门',
                    minWidth: '150px'
                },
                {
                    prop: 'enterprise_name',
                    label: '所属企业',
                    minWidth: '260px'
                },
            ],
            fposition_details_data: {},
            fAllenterprise: [],
            fenterprise_department:[]
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_position_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.status = param.status ?? this.cond.status
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            position_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_position(id) {
            this.fid = id
            this.fdialogFormVisible = true
            position_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fposition_details_data = s.result
                        this.fposition_details_data.department_id = [
                            s.result.enterprise_id,
                            s.result.department_id,
                        ]
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.fAllenterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.fenterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_position_index()
        this.get_all_enterprise()
        this.get_enterprise_department()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        PositionSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        PositionEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>