<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closePositionDialog('positionEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="position"
                    :rules="rules"
                    ref="positionEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="职位名称" prop="name">
                            <el-input v-model="position.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="所属部门" prop="department_id">
                            <el-cascader
                                v-model="position.department_id"
                                :options="fenterprise_department"
                                :props="{ expandTrigger: 'hover' }"
                                clearable
                                style="width: 100%"
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-select
                                v-model="position.status"
                                filterable
                                placeholder="请选择状态"
                                class="statusc"
                            >
                                <el-option
                                    v-for="(v,i) in ['禁用','启用']"
                                    :key="i"
                                    :label="v"
                                    :value="i"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>职位名称</span>
                                </template>
                                <span>{{position.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属部门</span>
                                </template>
                                <span>{{position.enterprise_name ? position.enterprise_name + '/' + position.department_name : ''}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>状态</span>
                                </template>
                                <el-tag v-if="position.status === 0" type="danger">禁用</el-tag>
                                <el-tag v-else-if="position.status === 1" type="success">可用</el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(allot_auth)"
                        type="warning"
                        plain
                        @click="fAuthRouteDialogVisible = true"
                        >职位授权</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="positionDel('positionEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closePositionDialog('positionEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('positionEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('positionEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closePositionDialog('positionEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('positionEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closePositionDialog('positionEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
            <common-auth-dialog
                :id="id"
                :AuthRouteDialogVisible="fAuthRouteDialogVisible"
                @exitAuthRouteDialog="fAuthRouteDialogVisible = false"
            ></common-auth-dialog>
        </el-dialog>
    </div>
</template>

<script>
import { position_add_request,position_del_request,position_edit_request } from '@/network/position.js'

import CommonAuthDialog from '@/components/common/CommonAuthDialog'

export default {
    name: 'PositionEditComponent',
    data() {
        return {
            login_loading: false,
            fAuthRouteDialogVisible: false,
            position: {
                name: '',
                department_id: '',
                department_name: '',
                enterprise_name: '',
                status: 1
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入职位名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
                department_id: [
                    { required: true, message: '请选择部门', trigger: 'blur' },
                ],
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '职位详情'
            } else if (this.show_type === 1) {
                return '添加职位'
            } else {
                return '修改职位'
            }
        },
    },
    methods: {
        closePositionDialog(formName) {
            this.position = {
                name: '',
                department_id: '',
                department_name: '',
                enterprise_name: '',
                status: 1
            }
            this.$emit('exitPositionDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.positionInsert(formName)
            })
        },
        positionInsert(formName) {
            this.login_loading = true
            let d = this.position
            d.department_id = d.department_id[d.department_id.length - 1]
            position_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitPositionDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.position = {
                name: '',
                department_id: '',
                department_name: '',
                enterprise_name: '',
                status: 1
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.positionEdit(formName)
            })
        },
        positionEdit(formName) {
            this.login_loading = true
            let d = this.position
            d.department_id = d.department_id[d.department_id.length - 1]
            position_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitPositionDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.position.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        positionDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    position_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closePositionDialog(formName))
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        position_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fenterprise_department: Array,
        edit_auth: String,
        del_auth: String,
        allot_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonAuthDialog
    },
    watch: {
        position_details_data(newVal) {
            this.position = newVal
        },
    },
}
</script>

<style>
.enterprisec,.statusc {
    width: 100%;
}
</style>